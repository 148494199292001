.hoox-shop-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 12;
}

.hoox-shop-popup__body {
    transform: scale(0.8);
    transition: all 0.3s ease-in;
}

.hoox-shop-popup.active {
    opacity: 1;
    visibility: visible;
}

.hoox-shop-popup.active .hoox-shop-popup__body {
    transform: scale(1);
    max-width: calc(100% - 50px);
    max-height: calc(100% - 40px);
    background-color: var(--hoox-color-white);
    padding: 20px;
    position: relative;
    z-index: 14;
}

.hoox-shop-popup__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 13;
}

.hoox-shop-popup__inner {
    position: relative;
}

.hoox-shop-popup__inner::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 32px;
    background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    z-index: 10;
}

.hoox-shop-popup__content {
    max-height: 442px;
    overflow: scroll;
    overflow-x: hidden;
    padding-right: 12px;
    padding-bottom: 12px;
}

.hoox-shop-popup__content::-webkit-scrollbar {
    width: 5px;
}

.hoox-shop-popup__content::-webkit-scrollbar-track {
    background-color: var(--hoox-color-slate-secondary);
    border-left: 2px solid var(--hoox-color-white);
    border-right: 2px solid var(--hoox-color-white);
}

.hoox-shop-popup__content::-webkit-scrollbar-thumb {
    background-color: var(--hoox-color-slate-secondary);
    border-radius: 12px;
}

.hoox-shop-popup__title {
    font-size: 24px;
    line-height: 125%;
    margin-bottom: 16px;
}

.hoox-shop-popup__close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
}

.hoox-shop-popup__content h6 {
    font-family: 'Century Old Style';
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 8px;
}

.hoox-shop-popup__content p {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 12px;
}

.hoox-shop-popup__content p:last-child {
    color: var(--hoox-color-grey-secondary);
}
.hoox-shop-popup__content p:last-child {
    margin-bottom: 0;
}

@media(min-width:768px) {
    .hoox-shop-popup__body {
        width: 650px;
        height: 395px;
        background: #FFF;
        padding: 30px;
        position: relative;
        z-index: 14;
    }

    .hoox-shop-popup__content {
        max-height: 300px;
    }
}

.hoox-shop-lp-2 .hoox-shop-popup__body, .hoox-shop-lp-3 .hoox-shop-popup__body  {
    height: unset;
}

.hoox-shop-lp-2 .hoox-shop-popup__content, .hoox-shop-lp-3 .hoox-shop-popup__content {
    max-height: unset;
    overflow: unset;
}

.hoox-shop-lp-2 .hoox-shop-popup__inner:after {
    display: none;
}

.hoox-shop-lp-2 .hoox-shop-popup__content p, .hoox-shop-lp-3 .hoox-shop-popup__content p {
    font-size: 14px;
}

.hoox-shop-lp-2 .hoox-shop-popup__content p strong, .hoox-shop-lp-3 .hoox-shop-popup__content p strong {
    font-family: "century-old-style-std",serif; 
    font-size: 16px;
    font-weight: 400;
}

.hoox-shop-lp-2 .hoox-shop-popup__close-btn,.hoox-shop-lp-3 .hoox-shop-popup__close-btn {
    right: -12px;
    top: -12px;
}

